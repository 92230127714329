import React, { Fragment } from "react";
import { Layout, SEO } from "src/components";
import {
  HeroTemplate,
  FAQSectionTemplate,
  BusinessDetailsTemplate
} from "src/services/prismic/templates";
import { graphql } from "gatsby";
import { PrismicPage } from "src/graphqlTypes";

type PressProps = {
  data: {
    prismicPage: PrismicPage;
  };
};

const Contact = ({ data: { prismicPage: page } }: PressProps) => {
  return (
    <Fragment>
      <SEO
        title={page.data?.title.text}
        description={page.data?.description.text}
        path="/contact"
      />

      <Layout>
        {page?.data?.body?.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyFaqSection":
              return <FAQSectionTemplate data={slice} key={idx} />;
            case "PrismicPageBodyBusinessDetailsSection":
              return <BusinessDetailsTemplate data={slice} key={idx} />;
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query {
    prismicPage(uid: { eq: "contact" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...PrismicFaqSection
          ...PrismicBusinessDetails
        }
      }
    }
  }
`;

export default Contact;
